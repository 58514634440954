import { Configure, Index } from 'react-instantsearch-dom';
import { ComponentType } from 'react';
import {
  Box, Typography,
} from '@mui/material';
import FeaturedHit from '../search/FeaturedHit';
import SearchLoadingIndicator from '../search/SearchLoadingIndicator';

export type ProductsShowcaseProps = {
  title?: string
  indexName: string
  indexId?: string
  className?: string
  hitComponent: ComponentType<any>
  [index: string]: any;
  url?: string;
}

const ProductsShowcase = ({
  indexName,
  indexId,
  title,
  hitComponent,
  ...searchParameters
}: ProductsShowcaseProps) => (
  <Index indexName={indexName} indexId={indexId}>
    <Configure {...searchParameters} />

    <Box marginTop={3} data-insights-index={indexName}>
      <Box marginBottom={1}>
        <Typography fontWeight="600" variant="h6">{title}</Typography>
      </Box>

      <SearchLoadingIndicator />

      <FeaturedHit
        hitComponent={hitComponent}
      />

    </Box>
  </Index>
);

export default ProductsShowcase;
