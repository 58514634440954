import {
  Box, Button, Container, Grid, IconButton, Link, Typography,
} from '@mui/material';
import { graphql, PageProps } from 'gatsby';
import { PauseCircle, PlayCircle } from 'mdi-material-ui';
import { FC, useState } from 'react';
import { Configure } from 'react-instantsearch-dom';
import ProductCardHit from '../components/app/ProductCardHit';
import SearchBox from '../components/pages/search/SearchBox';
import GenresList from '../components/pages/shelf/GenresList';
import ProductShowcase from '../components/pages/shelf/ProductShowcase';
import { indexName } from '../helpers/config';
import SearchProvider from '../providers/SearchProvider';

type IndexPageProps = PageProps<Queries.ShelfQuery>;

export const query = graphql`
  query Shelf {
      allShelf {
      nodes {
      title
      keyword
      }
    }
  }
`;

const IndexPage: FC<IndexPageProps> = ({
  data,
}) => {
  const [isPlayAudio, setIsPlayAudio] = useState(false);

  return (
    <>
      <Container>
        <GenresList />
      </Container>

      <Box sx={{ backgroundColor: 'rgba(193, 204, 244, 0.18)' }}>
        <Container sx={{ minHeight: 400, alignItems: 'center', display: 'flex' }}>
          <Box textAlign="center" width={['100%', '100%', 800]} maxWidth="100%" justifyContent="center" mx="auto">
            <IconButton onClick={() => setIsPlayAudio((state) => !state)} size="small">
              {isPlayAudio ? <PauseCircle fontSize="small" /> : <PlayCircle fontSize="small" />}
            </IconButton>
            <Typography variant="subtitle1" component="span">
              Listen to how we pronounce Litireso
            </Typography>
            {isPlayAudio && (
            <audio loop autoPlay>
              <source src="/audio/litireso.ogg" type="audio/ogg" />
              <source src="/audio/litireso.mp3" type="audio/mpeg" />
              Your browser does not support the audio element.
              <track kind="captions" />
            </audio>
            )}
            <Typography variant="h3" marginBottom={4} component="h1" fontWeight="bold" color="primary">Discover Homegrown African Books</Typography>

            <SearchProvider>
              <SearchBox sx={{ width: { md: 500, xs: '100%' } }} />
            </SearchProvider>

            <Grid container spacing={2} marginTop={3} justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  disableElevation
                  component={Link}
                  href="#lists"
                >
                  Read a book
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" size="large" disableElevation component={Link} href="/publish-a-book">Publish my book</Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box id="lists" marginBottom={3}>
        <Container>
          <SearchProvider>
            <Configure hitsPerPage={6} distinct />
            {data.allShelf?.nodes?.map((item) => (
              <ProductShowcase
                key={item.title}
                indexName={indexName}
                title={item.title}
                indexId={`book_${item.title}`}
                filters={`(${item.keyword}) AND isOutOfStock:false`}
                distinct
                hitComponent={ProductCardHit}
              />
            ))}
          </SearchProvider>
        </Container>
      </Box>
    </>
  );
};

export default IndexPage;
