import {
  ComponentType, memo,
} from 'react';
import isEqual from 'react-fast-compare';
import type { BasicDoc, Hit, HitsProvided } from 'react-instantsearch-core';
import { connectHits } from 'react-instantsearch-dom';

import { Grid } from '@mui/material';

export interface InfiniteHitsProps extends HitsProvided<BasicDoc> {
  hitComponent: ComponentType<any>;
  onClick?: (hit: Hit) => void;
}

const FeaturedHit = ({
  hits,
  hitComponent: HitComponent,
  onClick,
}: InfiniteHitsProps) => (
  <Grid
    container
    columnSpacing={{ xs: 2, md: 0 }}
    rowSpacing={{ xs: 2, md: 0 }}
  >
    {hits.map((hit) => (
      <Grid key={hit.objectID} item xs md="auto" display="flex">
        <HitComponent hit={hit} onClick={onClick} />
      </Grid>
    ))}
  </Grid>
);

export default connectHits<any, any>(memo(FeaturedHit, isEqual));
