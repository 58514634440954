import {
  Link, Theme, Typography, useMediaQuery, Chip, Box,
} from '@mui/material';
import { isEqual } from 'lodash';
import {
  ComponentType,
  FC, memo, useCallback, useMemo,
} from 'react';
import { connectHitInsights } from 'react-instantsearch-dom';
import { BasicDoc, Hit, WrappedInsightsClient } from 'react-instantsearch-core';
import searchInsights from 'search-insights';
import useAuth from '../../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import { BOOK_COVER_HEIGHT, BOOK_COVER_WIDTH } from '../../constants';
import { toGtagItem, trackSelectItem } from '../../helpers/gtag';
import { BookSchema } from '../../types/schema';
import BookCover from './BookCover';
import PricingText from './PricingText';
import Spacer from './Spacer';

type HitComponentProps<T> = {
  hit: Hit<T> & BasicDoc;
}

export type ProductCardHitProps = HitComponentProps<BookSchema> & {
  insights: WrappedInsightsClient;
  insightsEventName?: string;
   onClick?: () => void;
}

const ProductCardHitComponent: FC<ProductCardHitProps> = ({
  hit: {
    slug,
    cover,
    pricings,
    pricingType,
    title,
    isOutOfStock,
    type,
    releaseDate,
    ...hit
  },
  insights,
  insightsEventName,
  onClick,
}) => {
  const auth = useAuth();
  const matches = useMediaQuery<Theme>((theme) => theme.breakpoints.between('xs', 'md'));

  const imgWidth = useMemo(() => BOOK_COVER_WIDTH + (!matches ? 80 : 0), [matches]);
  const imgHeight = useMemo(() => BOOK_COVER_HEIGHT + (!matches ? 80 : 0), [matches]);
  // eslint-disable-next-line no-underscore-dangle
  const searchParams = new URLSearchParams({ queryID: hit.__queryID });

  const handleClick = useCallback(() => {
    insights('clickedObjectIDsAfterSearch', {
      eventName: insightsEventName,
    });

    trackSelectItem('Book Vertical Item', toGtagItem(
      [{
        title, slug, pricings, pricingType,
      }],
      { currencyCode: auth?.currencyCode, quantity: 1, listName: 'Book Vertical Item' },
    )[0]);
    onClick?.();
  }, [
    auth?.currencyCode,
    insights,
    insightsEventName,
    pricingType,
    pricings,
    slug,
    title,
    onClick,
  ]);

  return (
    <Box component="section">
      <Box
        component={Link}
        href={`/b/${slug}?${searchParams.toString()}`}
        color="inherit"
        underline="none"
        onClick={handleClick}
        sx={(theme) => ({
          [theme.breakpoints.between('xs', 'md')]: {
            width: BOOK_COVER_WIDTH,
            padding: 0,
          },
          width: BOOK_COVER_WIDTH + 80,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: theme.spacing(2),
          borderRadius: theme.spacing(3),
          [theme.breakpoints.up('md')]: {
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: '#fff',
            },
          },
        })}
      >
        <Box position="relative">
          <BookCover
            src={cover}
            width={imgWidth}
            height={imgHeight}
            borderRadius={2}
          />
          {(isOutOfStock || releaseDate) && (
          <Chip
            color={(isOutOfStock && !releaseDate) ? 'error' : 'info'}
            sx={{ position: 'absolute', bottom: 10, left: 10 }}
            label={(isOutOfStock && !releaseDate) ? 'Out of stock' : 'Preorder'}
          />
          )}
        </Box>
        <Typography noWrap variant="h6" component="h1" gutterBottom marginTop={1} fontWeight="600">{title}</Typography>
        <Spacer />
        <PricingText
          items={pricings}
          pricingType={pricingType}
          textAlign="unset"
        />

        <Typography textTransform="capitalize" fontStyle="italic">{`Format: ${type}`}</Typography>
      </Box>
    </Box>
  );
};

const WithProductCardHit = connectHitInsights(
  searchInsights,
)(memo(ProductCardHitComponent, isEqual)) as unknown as ComponentType<Omit<ProductCardHitProps, 'insights'>>;

const ProductCardHit = (props: Omit<ProductCardHitProps, 'insights'>) => (
  <WithProductCardHit
    {...props}
    insightsEventName="PLP;Product Clicked"
  />
);

export default ProductCardHit;
