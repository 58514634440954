import {
  Tabs, Tab,
} from '@mui/material';
import { graphql, StaticQuery } from 'gatsby';
import { FC } from 'react';

export const query = graphql`
  query AllGenres {
    allGenre {
      nodes {
        slug
        title
      }
    }
  }
`;

const GenresList: FC = () => (
  <Tabs
    value={0}
    variant="scrollable"
    scrollButtons
    allowScrollButtonsMobile
    sx={{ alignItems: 'center', marginY: 2 }}
    TabIndicatorProps={{ style: { display: 'none' } }}
  >
    <StaticQuery<Queries.AllGenresQuery> query={query}>
      {({ allGenre }) => (
        allGenre.nodes.map((item) => (
          <Tab
            key={item.title}
            label={item.title}
            href={`/search/${item.slug}`}
            sx={{ textTransform: 'capitalize' }}
          />
        )))}
    </StaticQuery>
  </Tabs>
);

export default GenresList;
