import {
  Box, BoxProps, Theme, Typography, useMediaQuery,
} from '@mui/material';
import {
  FC, useCallback, useMemo,
} from 'react';
import useCurrencyChooser from '../../../plugins/gatsby-plugin-currency-chooser/src/useCurrencyChooser';
import formatAmount from '../../helpers/formatAmount';
import { PricingSchemaType, PricingType } from '../../types/app';

type PricingTextProps = {
  items: PricingSchemaType[];
  pricingType: PricingType;
  textAlign?: BoxProps['textAlign'];
}

const PricingText: FC<PricingTextProps> = ({
  items,
  pricingType,
  textAlign,
}) => {
  const { currencyCode } = useCurrencyChooser();

  const matches = useMediaQuery<Theme>((theme) => theme.breakpoints.between('xs', 'md'));

  const pricing = useMemo(() => (
    items?.find((item) => item.currencyCode === currencyCode)
  ), [currencyCode, items]);

  const getPricingAmount = useCallback((value: number) => (
    formatAmount(value, currencyCode)
  ), [currencyCode]);

  return (
    <Box textAlign={textAlign || (matches ? 'center' : undefined)}>
      {(pricingType === 'free' || !pricing)
        ? 'free'
        : (
          <>
            <Typography
              sx={{
                textDecoration: pricing?.price ? 'line-through' : undefined,
                color: (theme) => (pricing.price ? theme.palette.error.main : undefined),
              }}
            >
              {getPricingAmount(pricing.amount)}
            </Typography>

            {!!pricing.price && (
              <Typography
                fontWeight={pricing.price ? '600' : undefined}
                sx={{ color: (theme) => (pricing.price ? theme.palette.success.main : undefined) }}
              >
                {` ${getPricingAmount(pricing.price)}`}
              </Typography>
            )}
          </>
        )}
    </Box>
  );
};

export default PricingText;
